import axios from 'axios';

// Crea una instancia de axios con la baseURL
const instance = axios.create({
  baseURL: 'https://api.msiglobalservices.com/api',
  withCredentials: true
});

// Configura el token de autorización si está disponible
const token = localStorage.getItem('token');
if (token) {
  instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default instance;
