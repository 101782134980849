import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig';
import { Table, Container, Button, Modal, Form, Alert } from 'react-bootstrap';

const Oficinas = () => {
  const [oficinas, setOficinas] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentOficina, setCurrentOficina] = useState(null);
  const [newOficina, setNewOficina] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    fetchOficinas();
  }, []);

  const fetchOficinas = async () => {
    try {
      const { data } = await axios.get('/oficinas');
      setOficinas(data);
    } catch (error) {
      console.error('Error fetching oficinas', error);
      setError('Error fetching oficinas');
    }
  };

  const handleAddOficina = () => {
    setNewOficina('');
    setShowEditModal(true);
  };

  const handleEditOficina = (oficina) => {
    setCurrentOficina(oficina);
    setShowEditModal(true);
  };

  const handleDeleteOficina = (oficina) => {
    setCurrentOficina(oficina);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`/oficinas/${currentOficina._id}`);
      setOficinas(oficinas.filter(o => o._id !== currentOficina._id));
      setShowDeleteModal(false);
      setSuccess('Oficina eliminada con éxito');
    } catch (error) {
      setError('Error al eliminar la oficina');
      setShowDeleteModal(false);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      let updatedListOficinas;
      if (currentOficina) {
        const { data } = await axios.put(`/oficinas/${currentOficina._id}`, { nombre: newOficina });
        updatedListOficinas = oficinas.map(o => o._id === currentOficina._id ? data : o);
      } else {
        const { data } = await axios.post('/oficinas', { nombre: newOficina });
        updatedListOficinas = [...oficinas, data];
      }
      setOficinas(updatedListOficinas);
      setShowEditModal(false);
      setSuccess('Oficina actualizada con éxito');
    } catch (error) {
      setError('Error al actualizar la oficina');
      setShowEditModal(false);
    }
  };

  return (
    <Container>
      <h1>Oficinas</h1>
      {error && <Alert variant="danger">{error}</Alert>}
      {success && <Alert variant="success">{success}</Alert>}
      <Button variant="primary" onClick={handleAddOficina} className="mb-3">
        Añadir Oficina
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {oficinas.map((oficina) => (
            <tr key={oficina._id}>
              <td>{oficina.nombre}</td>
              <td>
                <Button variant="warning" onClick={() => handleEditOficina(oficina)}>Editar</Button>
                <Button variant="danger" onClick={() => handleDeleteOficina(oficina)}>Eliminar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{currentOficina ? 'Editar Oficina' : 'Añadir Oficina'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="oficinaName">
              <Form.Label>Nombre de la Oficina</Form.Label>
              <Form.Control
                type="text"
                value={newOficina}
                onChange={(e) => setNewOficina(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              {currentOficina ? 'Guardar Cambios' : 'Añadir Oficina'}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          ¿Está seguro de que desea eliminar la oficina {currentOficina?.nombre}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancelar</Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>Eliminar</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Oficinas;
