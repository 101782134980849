import React, { useEffect, useState } from 'react';
import axios from '../axiosConfig'; // Importa la instancia configurada de Axios
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { Container, Row, Col, Card } from 'react-bootstrap';
import {
  Chart as ChartJS,
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  TimeScale,
} from 'chart.js';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  ArcElement,
  BarElement,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  Legend
);

const Dashboard = (props) => {
  const [ordenadores, setOrdenadores] = useState([]);

  useEffect(() => {

    const fetchOrdenadores = async () => {
      try {
        const { data } = await axios.get('/ordenadores');
        setOrdenadores(data);
      } catch (error) {
        console.error('Error fetching ordenadores', error);
      }
    };

    fetchOrdenadores();
  }, []);

  // Prepare data for Doughnut chart
  const statusCounts = ordenadores.reduce((acc, ordenador) => {
    acc[ordenador.status] = (acc[ordenador.status] || 0) + 1;
    return acc;
  }, {});

  const doughnutData = {
    labels: Object.keys(statusCounts),
    datasets: [
      {
        data: Object.values(statusCounts),
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  // Prepare data for Bar chart (Ordenadores por oficina)
  const officeCounts = ordenadores.reduce((acc, ordenador) => {
    const officeName = ordenador.oficina?.nombre || 'Sin Oficina';
    acc[officeName] = (acc[officeName] || 0) + 1;
    return acc;
  }, {});

  const officeBarData = {
    labels: Object.keys(officeCounts),
    datasets: [
      {
        label: 'Ordenadores por Oficina',
        data: Object.values(officeCounts),
        backgroundColor: '#36A2EB',
      },
    ],
  };

  // Prepare data for Bar chart (Ordenadores por modelos)
  const modelCounts = ordenadores.reduce((acc, ordenador) => {
    acc[ordenador.modelo] = (acc[ordenador.modelo] || 0) + 1;
    return acc;
  }, {});

  const modelBarData = {
    labels: Object.keys(modelCounts),
    datasets: [
      {
        label: 'Ordenadores por Modelo',
        data: Object.values(modelCounts),
        backgroundColor: '#FFCE56',
      },
    ],
  };

  // Prepare data for Line chart (Ordenadores asignados por mes)
  const assignmentCounts = ordenadores.reduce((acc, ordenador) => {
    if (ordenador.status === 'Asignado' && ordenador.updatedAt) {
      const month = new Date(ordenador.updatedAt).toLocaleString('default', { year: 'numeric', month: 'short' });
      acc[month] = (acc[month] || 0) + 1;
    }
    return acc;
  }, {});

  const sortedMonths = Object.keys(assignmentCounts).sort((a, b) => new Date(a) - new Date(b));

  const lineData = {
    labels: sortedMonths,
    datasets: [
      {
        label: 'Ordenadores Asignados por Mes',
        data: sortedMonths.map(month => assignmentCounts[month]),
        fill: false,
        backgroundColor: '#4BC0C0',
        borderColor: '#4BC0C0',
      },
    ],
  };

  return (
    <Container fluid>
      <h1>Dashboard</h1>
      <Row className='mb-3'>
        <Col md={6}>
          <Card className="mb-4 h-100">
            <Card.Body>
              <h3>Ordenadores por Status</h3>
              <div className="chart-container">
                <Doughnut data={doughnutData} options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4 h-100">
            <Card.Body>
              <h3>Ordenadores por Oficina</h3>
              <div className="chart-container">
                <Bar data={officeBarData} options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <Card className="mb-4 h-100">
            <Card.Body>
              <h3>Ordenadores por Modelo</h3>
              <div className="chart-container">
                <Bar data={modelBarData} options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }} />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="mb-4 h-100">
            <Card.Body>
              <h3>Ordenadores Asignados por Mes</h3>
              <div className="chart-container">
                <Line data={lineData} options={{
                  responsive: true,
                  maintainAspectRatio: false,
                }} />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
