import React, { useState } from 'react';
import axios from '../axiosConfig';
import * as XLSX from 'xlsx';
import { Container, Button, Modal, Form, Alert, Table } from 'react-bootstrap';

const Personio = () => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [discrepancias, setDiscrepancias] = useState([]);
  const [error, setError] = useState('');

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUploadForComparison = async () => {
    if (!file) {
      setError('Por favor, seleccione un archivo para cargar.');
      return;
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const data = XLSX.utils.sheet_to_json(sheet);

      try {
        const response = await axios.post('/empleados/compare', data);
        setShowModal(false); // Cerrar el modal después de la carga y comparación
        if (response.data.discrepancias && response.data.discrepancias.length > 0) {
          setDiscrepancias(response.data.discrepancias);
          setError('');
        } else {
          setDiscrepancias([]);
          setError('Todos los datos coinciden correctamente.');
        }
      } catch (error) {
        console.error('Error during file comparison', error);
        setError('Error al procesar el archivo para comparación.');
      }
    };
    reader.readAsBinaryString(file);
  };

  const handleDownloadSample = () => {
    const sampleData = [
      { "employeeId": "E001", "status": "Active" },
      { "employeeId": "E002", "status": "Inactive" }
    ];
    const worksheet = XLSX.utils.json_to_sheet(sampleData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sample');
    XLSX.writeFile(workbook, 'SampleData.xlsx');
  };

  return (
    <Container className='mb-2'>
      <h1>Comparar Datos de MSI con Personio</h1>
      <Button variant="primary" onClick={() => setShowModal(true)}>
        Subir y Comparar Datos
      </Button>
      <Button variant="info" onClick={handleDownloadSample} className="ms-2">
        Descargar Archivo de Muestra
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Verificar Empleados activos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="fileUpload">
              <Form.Label>Subir Archivo Excel</Form.Label>
              <Form.Control type="file" accept=".xlsx, .xls" onChange={handleFileChange} />
            </Form.Group>
            <div className="mt-3">
              <Button variant="success" onClick={handleFileUploadForComparison}>
                Comparar Archivo
              </Button>
            </div>
          </Form>
          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
        </Modal.Body>
      </Modal>

      {discrepancias.length > 0 && (
        <div className="mt-3">
          <h3>Discrepancias Encontradas</h3>
          <Table striped bordered hover size="sm">
            <thead>
              <tr>
                <th>ID de Empleado</th>
                <th>Mensaje General</th>
                <th>Estado Esperado</th>
                <th>Estado Real</th>
              </tr>
            </thead>
            <tbody>
              {discrepancias.map((d, idx) => (
                <tr key={idx}>
                  <td>{d.employeeId}</td>
                  <td>{d.message || 'Detalles de comparación'}</td>
                  <td>{d.expected ? d.expected.activo : "N/A"}</td>
                  <td>{d.received ? d.received.activo : "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    </Container>
  );
};

export default Personio;
