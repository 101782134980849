import React from 'react';
import { Button, Container, Row, Col } from 'react-bootstrap';

const Login = ({ setAuth }) => {

  const handleGoogleLogin = () => {
    window.location.href = 'https://api.msiglobalservices.com/api/auth/login/sso/callback';
  };

  return (
    <Container>
      <Row className="justify-content">
        <Col xs={12} md={6}>
          <h1 className='mt-3'>Iniciar Sesión</h1>
          <Button
            variant="light"
            onClick={handleGoogleLogin}
            className="mt-3"
            style={{
              border: '1px solid #ddd',
              boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px',
            }}
          >
            <img
              src="https://developers.google.com/identity/images/g-logo.png"
              alt="Google logo"
              style={{ marginRight: '8px', height: '20px' }}
            />
            Iniciar sesión con Google
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;